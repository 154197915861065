import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'points_of_interest_commerce';
const namespace = 'ui-pdp-icon ui-pdp-icon--poi-commerce';

const IconCommerce = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconCommerce.propTypes = {
  className: string,
};

IconCommerce.defaultProps = {
  className: null,
};

IconCommerce.ICON_ID = ICON_ID;

export default React.memo(IconCommerce);
export { IconCommerce };
